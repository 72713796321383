var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"datatable",attrs:{"responsive":"","bordered":"","small":"","items":_vm.modified,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(stageOnePrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatObjectCell(item.stageOnePrice))}})]}},{key:"cell(stageTwoPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatObjectCell(item.stageTwoPrice))}})]}},{key:"cell(stageTwoPlusPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatObjectCell(item.stageTwoPlusPrice))}})]}},{key:"cell(stageThreePrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatObjectCell(item.stageThreePrice))}})]}},{key:"cell(stageFourPrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatObjectCell(item.stageFourPrice))}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }