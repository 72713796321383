<template>
  <b-table responsive class="datatable" bordered small :items="modified" :fields="fields">

  </b-table>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "ImporterTcu",
  props: {
    to_compare: Array
  },
  data: () => ({
    imported: [],
    modified: [],
    headers: {
      id: 'N',
      dsg: 'DSG',
      stockPrice: 'DRIVABILITY',
      stageOnePrice: 'STAGE I',
      stageTwoPrice: 'STAGE II',
      stageTwoPlusPrice: 'STAGE II+',
      stageThreePrice: 'STAGE III',
      stageFourPrice: 'STAGE IV'
    },
    fields: [
      'origin',
      'id',
      'dsg',
      {key: 'stockPrice', label: 'DRIVABILITY'},
      {key: 'stageOnePrice', label: 'Stage I'},
      {key: 'stageTwoPrice', label: 'Stage II'},
      {key: 'stageTwoPlusPrice', label: 'Stage II+'},
      {key: 'stageThreePrice', label: 'Stage III'},
      {key: 'stageFourPrice', label: 'Stage IV'},
    ],
    toObject: [
      'stockPrice',
      'stageOnePrice',
      'stageTwoPrice',
      'stageTwoPlusPrice',
      'stageThreePrice',
      'stageFourPrice',
    ]
  }),
  methods: {
    read(sheet) {
      this.imported = XLSX.utils.sheet_to_json(sheet, {raw: true})
        .map(row => {
          const formated = {};
          Object.keys(this.headers).forEach(key => {
            formated[key] = row[this.headers[key]].toString()
          })
          return formated
        })
      this.handleCompare();
    },
    handleCompare() {
      this.imported.forEach(imported_row => {
        const inTvs = this.to_compare.find(el => Number(el.id) === Number(imported_row.id));
        if (inTvs) {
          const imported = {
            stockPrice: imported_row.stockPrice.toString().trim(),
            stageOnePrice: imported_row.stageOnePrice.toString().trim(),
            stageTwoPrice: imported_row.stageTwoPrice.toString().trim(),
            stageTwoPlusPrice: imported_row.stageTwoPlusPrice.toString().trim(),
            stageThreePrice: imported_row.stageThreePrice.toString().trim(),
            stageFourPrice: imported_row.stageFourPrice.toString().trim()
          }
          const in_tvs = {
            stockPrice: inTvs.stockPrice.toString().trim(),
            stageOnePrice: inTvs.stageOnePrice.toString().trim(),
            stageTwoPrice: inTvs.stageTwoPrice.toString().trim(),
            stageTwoPlusPrice: inTvs.stageTwoPlusPrice.toString().trim(),
            stageThreePrice: inTvs.stageThreePrice.toString().trim(),
            stageFourPrice: inTvs.stageFourPrice.toString().trim()
          }
          if (JSON.stringify(imported) !== JSON.stringify(in_tvs)) {
            this.modified.push({origin: 'TVS', ...inTvs})
            this.modified.push({origin: 'NEW', ...this.addCellVarians(inTvs, imported_row)})
          }
        }
      })
    },
    addCellVarians(tvs, object) {
      object._cellVariants = {};
      this.toObject.forEach(field => {
        if (!isNaN(tvs[field])) {
          const areIqual = Number(tvs[field]) === Number(object[field])
          if (!areIqual) {
            object._cellVariants[field] = Number(tvs[field]) > Number(object[field]) ? 'danger' : 'success'
          }
        } else if (tvs[field] != object[field]) {
          object._cellVariants[field] = 'warning'
        }
      })
      return object
    },
  }
}
</script>

<style scoped>

</style>
