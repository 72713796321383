var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-3"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"form-group"},[_c('b-form-file',{ref:"input",staticClass:"mb-3",on:{"change":_vm.readFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('div',{staticClass:"dataTables_wrapper VueTables VueTables--client"},[_c('div',{staticClass:"p-3"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_c('h4',[_vm._v("Comparison of differences in vehicles")])]),_c('b-col',[_c('b-form-input',{attrs:{"placeholder":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1)],1),_c('b-tabs',{staticClass:"custom-tab",attrs:{"justified":""}},[_c('b-tab',{attrs:{"title":"ECU"}},[_c('importer-ecu',{ref:"ecu",attrs:{"to_compare":_vm.to_compare.ecu}})],1),_c('b-tab',{attrs:{"title":"TCU"}},[_c('importer-tcu',{ref:"tcu",attrs:{"to_compare":_vm.to_compare.tcu}})],1),_c('b-tab',{attrs:{"title":"COMBOS"}},[_c('importer-combos',{ref:"combos",attrs:{"to_compare":_vm.to_compare.combos}})],1)],1),_c('div',{staticClass:"p-3"},[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{attrs:{"lg":"4"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('h5',[_vm._v("Save price list")]),_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Country")]),_c('b-form-select',{attrs:{"options":_vm.country_options},model:{value:(_vm.model.country_id),callback:function ($$v) {_vm.$set(_vm.model, "country_id", $$v)},expression:"model.country_id"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select")])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"to charge"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('b-checkbox',{model:{value:(_vm.model.to_charge),callback:function ($$v) {_vm.$set(_vm.model, "to_charge", $$v)},expression:"model.to_charge"}},[_vm._v("Use price to charge (Tvs Group Platform)")]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.model.to_charge)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Distributor. (optional)")]),_c('b-form-select',{staticClass:"form-control",model:{value:(_vm.model.distributor_id),callback:function ($$v) {_vm.$set(_vm.model, "distributor_id", $$v)},expression:"model.distributor_id"}},[_c('option',{domProps:{"value":null}},[_vm._v("Select a Distributor")]),_vm._l((_vm.clientList),function(client){return _c('option',{domProps:{"value":client.id}},[_vm._v(_vm._s(client.fullName))])})],2),_c('span',{staticClass:"text-dark"},[_vm._v("Note: if a Distributor is selected the price difference will be refund to that distributor (Only if the new price is higher than TVS price)")])],1):_vm._e(),(_vm.model.distributor_id)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Minimum price")]),_c('b-form-input',{model:{value:(_vm.model.minimum_price),callback:function ($$v) {_vm.$set(_vm.model, "minimum_price", $$v)},expression:"model.minimum_price"}})],1):_vm._e(),(_vm.model.distributor_id)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Percentage \"On request\" and/or Edit price")]),_c('b-form-input',{attrs:{"type":"range","min":"1","max":"2","step":".01"},model:{value:(_vm.model.percentage_on_request),callback:function ($$v) {_vm.$set(_vm.model, "percentage_on_request", $$v)},expression:"model.percentage_on_request"}}),_vm._v(" "+_vm._s(((_vm.model.percentage_on_request - 1) * 100).toFixed())+"% ")],1):_vm._e(),_c('validation-provider',{attrs:{"name":"to show"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('b-checkbox',{model:{value:(_vm.model.to_show),callback:function ($$v) {_vm.$set(_vm.model, "to_show", $$v)},expression:"model.to_show"}},[_vm._v("Use price to show (tvsengineering.com)")]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement : 'top',  arrow: true }),expression:"{ placement : 'top',  arrow: true }"}],attrs:{"rules":"required","content":"Replace: Delete database and add the new database.\n                                       <br>\n                                        Join:Filter by ID and update the row if it exists"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"title":"Replace or Join?"}},[_c('label',[_vm._v("Replace or Join "),_c('span',{staticClass:"material-icons"},[_vm._v("help_outline")])]),_c('b-form-select',{model:{value:(_vm.model.old_data),callback:function ($$v) {_vm.$set(_vm.model, "old_data", $$v)},expression:"model.old_data"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select")]),_c('b-form-select-option',{attrs:{"value":"replace"}},[_vm._v("Replace")]),_c('b-form-select-option',{attrs:{"value":"join"}},[_vm._v("Join")])],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.model.old_data === 'replace')?_c('span',{staticClass:"text-dark"},[_vm._v(" The system will delete old values for this country and save only the price list currently imported ")]):_vm._e(),(_vm.model.old_data === 'join')?_c('span',{staticClass:"text-dark"},[_vm._v(" The system will overwrite the modified prices in this list, but it will also keep the previous values unchanged ")]):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('b-button',{staticClass:"btn-block",attrs:{"disabled":_vm.tvs_policy=== '',"type":"submit","variant":"dark"}},[_vm._v("SUBMIT")]),_c('p',{staticClass:"text-danger text-center"},[_vm._v("Note: TVS must have pricing policies created first")])],1)],1)]}}])})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }