<template>
  <b-table responsive class="datatable" bordered small :items="modified" :fields="fields">
  </b-table>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "ImporterCombos",
  props: {
    to_compare: Array
  },
  data: () => ({
    imported: [],
    modified: [],
    headers: {
      'dsg': 'DSG',
      '1-1': "ECU STAGE I DSG STAGE I",
      '2-2': "ECU STAGE II DSG STAGE II",
      '2-2.1': "ECU STAGE II DSG STAGE II+",
      '2.1-2.1': "ECU STAGE II+ DSG STAGE II+",
      '2-3': "ECU STAGE II DSG STAGE III",
      '2.1-3': "ECU STAGE II+ DSG STAGE III",
      '3-3': "ECU STAGE III DSG STAGE III",
      '3-4': "ECU STAGE III DSG STAGE IV",
      '4-3': "ECU STAGE IV DSG STAGE III",
      '4-4': "ECU STAGE IV DSG STAGE IV",
      '2.1-4': "ECU STAGE II+ DSG STAGE IV",
    },
    fields: [
      'origin',
      'dsg',
      {key: '1-1', label: "ECU STAGE I DSG STAGE I"},
      {key: '2-2', label: "ECU STAGE II DSG STAGE II"},
      {key: '2-3', label: "ECU STAGE II DSG STAGE III"},
      {key: '2-2.1', label: "ECU STAGE II DSG STAGE II+"},
      {key: '2.1-2.1', label: "ECU STAGE II+ DSG STAGE II+"},
      {key: '2.1-3', label: "ECU STAGE II+ DSG STAGE III"},
      {key: '3-3', label: "ECU STAGE III DSG STAGE III"},
      {key: '3-4', label: "ECU STAGE III DSG STAGE IV"},
      {key: '4-3', label: "ECU STAGE IV DSG STAGE III"},
      {key: '4-4', label: "ECU STAGE IV DSG STAGE IV"},
      {key: '2.1-4', label: "ECU STAGE II+ DSG STAGE IV"},
    ],
    toObject: [
      '1-1',
      '2-2',
      '2-2.1',
      '2-3',
      '2.1-2.1',
      '2.1-3',
      '3-3',
      '3-4',
      '4-3',
      '4-4',
      '2.1-4'
    ]
  }),
  methods: {
    read(sheet) {
      this.imported = XLSX.utils.sheet_to_json(sheet, {raw: true})
        .map(row => {
          const formated = {};
          Object.keys(this.headers).forEach(key => {
            formated[key] = row[this.headers[key]].toString()
          })
          return formated
        })
      this.handleCompare();
    },
    handleCompare() {
      this.imported.forEach(imported_row => {
        const inTvs = this.to_compare.find(el => (el.dsg) === (imported_row.dsg));
        if (inTvs) {
          const imported = {};
          const in_tvs = {};
          this.toObject.forEach(k => {
            imported[k] = imported_row[k].toString().trim()
            in_tvs[k] = inTvs[k] ? inTvs[k].toString().trim() : ''
          });
          if (JSON.stringify(imported) !== JSON.stringify(in_tvs)) {
            this.modified.push({origin: 'TVS', ...inTvs})
            this.modified.push({origin: 'NEW', ...this.addCellVarians(inTvs, imported_row)})
          }
        } else {
          console.warn('error no encontrado', imported_row)
        }
      })
    },
    addCellVarians(tvs, object) {
      object._cellVariants = {};
      this.toObject.forEach(field => {
        if (!isNaN(tvs[field])) {
          const areIqual = Number(tvs[field]) === Number(object[field])
          if (!areIqual) {
            object._cellVariants[field] = Number(tvs[field]) > Number(object[field]) ? 'danger' : 'success'
          }
        } else if (tvs[field] != object[field]) {
          object._cellVariants[field] = 'warning'
        }
      })
      return object
    },
  }
}
</script>

<style scoped>

</style>
