<template>
  <div class="p-3">
    <b-row>
      <b-col>
        <div class="form-group">
          <b-form-file class="mb-3" v-model="file" @change="readFile" ref="input"/>
        </div>
        <div class="dataTables_wrapper VueTables VueTables--client">
          <div class="p-3">
            <b-row no-gutters>
              <b-col>
                <h4>Comparison of differences in vehicles</h4>
              </b-col>
              <b-col>
                <b-form-input v-model="query" placeholder="Search"/>
              </b-col>
            </b-row>
          </div>
          <b-tabs justified class="custom-tab">
            <b-tab title="ECU">
              <importer-ecu :to_compare="to_compare.ecu" ref="ecu"/>
            </b-tab>
            <b-tab title="TCU">
              <importer-tcu :to_compare="to_compare.tcu" ref="tcu"/>
            </b-tab>
            <b-tab title="COMBOS">
              <importer-combos :to_compare="to_compare.combos" ref="combos"/>
            </b-tab>
          </b-tabs>
          <div class="p-3">
            <b-row class="justify-content-end">
              <b-col lg="4">
                <validation-observer v-slot="{handleSubmit}">
                  <form @submit.prevent="handleSubmit(save)">
                    <h5>Save price list</h5>
                    <validation-provider name="country" rules="required" v-slot="{errors}">
                      <div class="form-group">
                        <label>Country</label>
                        <b-form-select :options="country_options" v-model="model.country_id">
                          <b-form-select-option :value="null">Select</b-form-select-option>
                        </b-form-select>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                    <validation-provider name="to charge" v-slot="{errors}">
                      <div class="form-group">
                        <b-checkbox v-model="model.to_charge">Use price to charge (Tvs Group Platform)</b-checkbox>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                    <div class="form-group" v-if="model.to_charge">
                      <label>Distributor. (optional)</label>
                      <b-form-select v-model="model.distributor_id" class="form-control">
                        <option :value="null">Select a Distributor</option>
                        <option v-for="client of clientList" :value="client.id">{{ client.fullName }}</option>
                      </b-form-select>
                      <span class="text-dark">Note: if a Distributor is selected the price difference will be refund to
                        that distributor (Only if the new price is higher than TVS price)</span>
                    </div>
                    <div class="form-group" v-if="model.distributor_id">
                      <label>Minimum price</label>
                      <b-form-input v-model="model.minimum_price"/>
                    </div>
                    <div class="form-group" v-if="model.distributor_id">
                      <label>Percentage "On request" and/or Edit price</label>
                      <b-form-input type="range" min="1" max="2" step=".01"
                                    v-model="model.percentage_on_request"/>
                      {{ ((model.percentage_on_request - 1) * 100).toFixed() }}%
                    </div>
                    <validation-provider name="to show" v-slot="{errors}">
                      <div class="form-group">
                        <b-checkbox v-model="model.to_show">Use price to show (tvsengineering.com)</b-checkbox>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                    <validation-provider rules="required" v-slot="{errors}"
                                         v-tippy="{ placement : 'top',  arrow: true }"
                                         content="Replace: Delete database and add the new database.
                                         <br>
                                          Join:Filter by ID and update the row if it exists">
                      <div class="form-group" title="Replace or Join?">
                        <label>Replace or Join
                          <span class="material-icons">help_outline</span>
                        </label>
                        <b-form-select v-model="model.old_data">
                          <b-form-select-option :value="null">Select</b-form-select-option>
                          <b-form-select-option value="replace">Replace</b-form-select-option>
                          <b-form-select-option value="join">Join</b-form-select-option>
                        </b-form-select>
                        <span class="text-danger">{{ errors[0] }}</span>
                        <span class="text-dark" v-if="model.old_data === 'replace'">
                          The system will delete old values for this country and save only the price list currently imported
                        </span>
                        <span class="text-dark" v-if="model.old_data === 'join'">
                          The system will overwrite the modified prices in this list, but it will also keep the previous values unchanged
                        </span>
                      </div>
                    </validation-provider>
                    <div class="form-group">
                      <b-button :disabled="tvs_policy=== ''" type="submit" class="btn-block" variant="dark">SUBMIT</b-button>
                      <p class="text-danger text-center">Note: TVS must have pricing policies created first</p>
                    </div>
                  </form>
                </validation-observer>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import XLSX from "xlsx";
import {get, post} from "@/services/api";
import ImporterEcu from "@/views/dashboard/vehicles-price-config/components/ImporterEcu";
import ImporterTcu from "@/views/dashboard/vehicles-price-config/components/ImporterTcu";
import ImporterCombos from "@/views/dashboard/vehicles-price-config/components/ImporterCombos";
import {UserRole} from "@/utils/auth.roles";
import show_alert_mixin from "@/utils/show_alert_mixin";


export default {
  name: "Importer",
  mixins: [show_alert_mixin],
  components: {ImporterCombos, ImporterTcu, ImporterEcu},
  data: () => ({
    to_compare: {
      ecu: [],
      tcu: [],
      combos: []
    },
    country_options: [],
    model: {
      country_id: null,
      to_charge: false,
      to_show: false,
      old_data: null,
      distributor_id: null,
      minimum_price: null,
      percentage_on_request: 1
    },
    fileChosen: false,
    file: null,
    clientList: [],
    tvs_policy: ""
  }),
  methods: {
    async readFile(e) {
      const file = e.target.files[0];
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.fileChosen = true;
        const reader = new FileReader();
        this.showLoader().then()
        reader.onloadend = () => {
          const arrayBuffer = reader.result;
          const options = {type: 'array'};
          const workbook = XLSX.read(arrayBuffer, options);
          this.$refs.ecu.modified = []
          this.$refs.tcu.modified = []
          this.$refs.combos.modified = []
          this.$refs.ecu.read(workbook.Sheets['ECU']);
          this.$refs.tcu.read(workbook.Sheets['TCU']);
          this.$refs.combos.read(workbook.Sheets['COMBOS']);
          this.$swal.close();
          this.file = null;
        }
        reader.readAsArrayBuffer(file)
      } else {
        this.showAlertNotification('File not permitted', 'error')
      }
    },
    async showLoader() {
      return this.$swal({
        title: 'Comparing, please wait...',
        didOpen: () => {
          this.$swal.showLoading()
        },
        background: '#c9c9c9'
      });
    },
    toCompare() {
      get('vehicles-price-config/to-compare').then(({data}) => {
        this.to_compare = data;
      })
    },
    getCountries() {
      get('geo/countries', null, true).then(({data}) => {
        this.country_options = data.map(row => ({
          value: row.id,
          text: row.name
        }))
      })
    },
    loadClients() {
      const roles = UserRole;
      get(`user/${roles.Distributor}?country=${this.model.country_id}`, null, true)
        .then(({data}) => {
          this.clientList = data;
        })
    },
    save() {
      if (!this.model.to_charge && !this.model.to_show) {
        this.showAlertNotification('You must select at least one price option', 'error')
        return;
      }
      if (!this.fileChosen) {
        this.showAlertNotification('No file chosen!', 'error')
      }
      if (!this.$refs.ecu.modified.length && !this.$refs.tcu.modified.length && !this.$refs.combos.modified.length) {
        this.showAlertNotification('No differences to upload','error')
        return
      }
      this.$store.commit('loaderManager', true)
      post('vehicles-price-config/save', {
        ...this.model,
        ecu: this.$refs.ecu.modified.filter(el => el.origin === 'NEW').map(row => {
          const value = {
            id: row.id,
            country_id: this.model.country_id,
            to_show: this.model.to_show,
            to_charge: this.model.to_charge,
            distributor_id: this.model.distributor_id
          }
          this.$refs.ecu.toObject.forEach(key => {
            if (typeof row[key] === 'object') {
              const r_value = {};
              Object.keys(row[key]).forEach(field => {
                r_value[field] = row[key][field].value ? row[key][field].value : row[key][field]
              });
              value[key] = r_value
            } else {
              value[key] = Number(row[key])
            }
          })
          return value;
        }),
        tcu: this.$refs.tcu.modified.filter(el => el.origin === 'NEW').map(row => ({
          dsg: row.dsg,
          country_id: this.model.country_id,
          stockPrice: Number(row.stockPrice),
          stageOnePrice: Number(row.stageOnePrice),
          stageTwoPrice: Number(row.stageTwoPrice),
          stageTwoPlusPrice: Number(row.stageTwoPlusPrice),
          stageThreePrice: Number(row.stageThreePrice),
          stageFourPrice: Number(row.stageFourPrice),
          to_show: this.model.to_show,
          to_charge: this.model.to_charge,
          distributor_id: this.model.distributor_id
        })),
        combos: this.$refs.combos.modified.filter(el => el.origin === 'NEW').map((row) => {
          const combos = [];
          this.$refs.combos.toObject.forEach(field => {
            const [ecu, dsg] = field.split('-')
            if (row[field].toUpperCase() !== 'X') {
              combos.push({
                ecu, dsg, price: Number(row[field])
              })
            }
          })
          return {
            name: row.dsg,
            country_id: this.model.country_id,
            to_show: this.model.to_show,
            to_charge: this.model.to_charge,
            distributor_id: this.model.distributor_id,
            combos
          }
        })
      }).then(async () => {
        this.$store.commit('loaderManager', false)
        this.showAlertNotification('Price list updated')
        this.$router.push({name:"index-vehicles-price-config"})
      })
        .catch(async () => {
          this.$store.commit('loaderManager', false)
          await this.$swal({
            title: 'Form submission failed!',
            text: 'Failed please try again later',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#d14343',
          });
        });
    },
    async geTvsPolicyPrices(){
      const {data} = await get("common/price-policy", null, true)
      this.tvs_policy = data
    }
  },
  mounted() {
    this.toCompare();
    this.getCountries()
    this.loadClients()
    this.geTvsPolicyPrices()
  },
  watch: {
    'model.country_id' (value){
      this.loadClients()
    }
  }
}
</script>

<style>
.bg-in-success {
  background: #beefd7;
}

.bg-in-danger {
  background: #f2caca;
}

.bg-in-warning {
  background: #ffb4004f;
}
</style>
