<template>
  <b-table responsive class="datatable" bordered small :items="modified" :fields="fields">
    <template #cell(stageOnePrice)="{item}" class="p-0">
      <span v-html="formatObjectCell(item.stageOnePrice)"></span>
    </template>
    <template #cell(stageTwoPrice)="{item}">
      <span v-html="formatObjectCell(item.stageTwoPrice)"></span>
    </template>
    <template #cell(stageTwoPlusPrice)="{item}">
      <span v-html="formatObjectCell(item.stageTwoPlusPrice)"></span>
    </template>
    <template #cell(stageThreePrice)="{item}">
      <span v-html="formatObjectCell(item.stageThreePrice)"></span>
    </template>
    <template #cell(stageFourPrice)="{item}">
      <span v-html="formatObjectCell(item.stageFourPrice)"></span>
    </template>
  </b-table>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "ImporterEcu",
  props: {
    to_compare: Array
  },
  data: () => ({
    imported: [],
    modified: [],
    headers: {
      id: 'N',
      brand: 'BRAND',
      model: 'MODEL',
      generation: 'GENERATION',
      engine: 'ENGINE',
      stageOnePrice: 'STAGE I',
      stageTwoPrice: 'STAGE II',
      stageTwoPlusPrice: 'STAGE II+',
      stageThreePrice: 'STAGE III',
      stageFourPrice: 'STAGE IV'
    },
    toObject: [
      'stageOnePrice',
      'stageTwoPrice',
      'stageTwoPlusPrice',
      'stageThreePrice',
      'stageFourPrice',
    ],
    fields: [
      'origin',
      'id',
      'brand',
      'model',
      'generation',
      'engine',
      {key: 'stageOnePrice', label: 'Stage I', tdClass: 'p-0'},
      {key: 'stageTwoPrice', label: 'Stage II', tdClass: 'p-0'},
      {key: 'stageTwoPlusPrice', label: 'Stage II+', tdClass: 'p-0'},
      {key: 'stageThreePrice', label: 'Stage III', tdClass: 'p-0'},
      {key: 'stageFourPrice', label: 'Stage IV', tdClass: 'p-0'},
    ],
  }),
  methods:{
    read(sheet){
      this.imported = XLSX.utils.sheet_to_json(sheet, {raw: true})
        .map(row => {
          const formated = {};
          Object.keys(this.headers).forEach(key => {
            if (this.toObject.includes(key)) {
              const value = row[this.headers[key]]
              if (value && isNaN(value) && value != 'X') {
                const obj = {};
                value.split(';')
                  .map(el => el.trim().split('='))
                  .forEach(val => {
                    obj[val[0]] = val[1] !== undefined ? val[1].toString(): ''
                  });
                formated[key] = obj
              } else {
                formated[key] = row[this.headers[key]].toString()
              }
            } else {
              formated[key] = row[this.headers[key]]? row[this.headers[key]].toString().toString() : ''
            }
          })
          return formated
        })
      this.handleCompare();
    },
    handleCompare() {
      this.imported.forEach(imported_row => {
        const inTvs = this.to_compare.find(el => Number(el.id) === Number(imported_row.id));
        if (inTvs) {
          const imported = {
            stageOnePrice: this.check(imported_row.stageOnePrice),
            stageTwoPrice: this.check(imported_row.stageTwoPrice),
            stageTwoPlusPrice: this.check(imported_row.stageTwoPlusPrice),
            stageThreePrice: this.check(imported_row.stageThreePrice),
            stageFourPrice: this.check(imported_row.stageFourPrice)
          };
          const in_tvs = {
            stageOnePrice: this.check(inTvs.stageOnePrice),
            stageTwoPrice: this.check(inTvs.stageTwoPrice),
            stageTwoPlusPrice: this.check(inTvs.stageTwoPlusPrice),
            stageThreePrice: this.check(inTvs.stageThreePrice),
            stageFourPrice: this.check(inTvs.stageFourPrice)
          }
          if (JSON.stringify(imported) !== JSON.stringify(in_tvs)) {
            this.modified.push({origin: 'TVS', ...inTvs})
            this.modified.push({origin: 'NEW', ...this.addCellVarians(inTvs, imported_row)})
          }
        } else {
          console.warn('error no encontrado', imported_row)
        }
      })
    },
    check(data){
      if (typeof data == 'string'){
        return data.trim();
      }
      return data;
    },
    addCellVarians(tvs, object) {
      object._cellVariants = {};
      this.toObject.forEach(field => {
        if (typeof object[field] == 'object') {
          const object_value = object[field];
          const tvs_value = tvs[field];
          Object.keys(object_value).forEach(key => {
            const are_same = tvs_value[key] === object_value[key]
            if (!are_same) {
              const areIqual = Number(tvs_value[key]) === Number(object_value[key])
              if (!areIqual) {
                if (!isNaN(tvs_value[key]) && !isNaN(object_value[key])){
                  object_value[key] = {
                    class: Number(tvs_value[key]) > Number(object_value[key]) ?
                      'bg-in-danger' : 'bg-in-success',
                    value: object_value[key]
                  }
                } else {
                  object_value[key] = {
                    class: 'bg-in-warning', value: object_value[key]
                  }
                }
              }
            }
          });
          object[field] = object_value;
        } else if (!isNaN(tvs[field])) {
          const areIqual = Number(tvs[field]) === Number(object[field])
          if (!areIqual) {
            object._cellVariants[field] = Number(tvs[field]) > Number(object[field]) ? 'danger' : 'success'
          }
        } else if (tvs[field] != object[field]) {
          object._cellVariants[field] = 'warning'
        }
      })
      return object
    },
    formatObjectCell(item) {
      if (typeof item === 'object') {
        const lines = []
        Object.keys(item).forEach(key => {
          if (typeof item[key] == 'object') {
            lines.push(`<div class="p-1 ${item[key].class}">${key} = ${item[key].value}</div>`)
          } else {
            lines.push(`<div class="p-1">${key} = ${item[key]}</div>`)
          }
        })
        return `<div>${lines.join('')}</div>`;
      } else {
        return `<div class="p-1">${item}</div>`;
      }
    },
  }
}
</script>

<style scoped>

</style>
